<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <set-message @publish="handlePublish" :text="{ t1: '任务名称', t2: '消息内容', t3: '文本信息' }" users="users" messageCont="messageContents">
      <!-- <template slot="appoint-crowd">
        <el-form-item label="使用客户">
          <el-button @click="selectCustomers">选择客户</el-button>
        </el-form-item>
      </template>-->
      <!-- <template slot="welcome-way">
        <el-form-item label="选择类型">
          <el-select v-model="form.welcomeWay" class="width-370" placeholder="请选择">
            <el-option v-for="(val, key) in { NONE: '不使用', NEW: '新建', DEFAULT: '默认' }" :value="key" :label="val" :key="key"></el-option>
          </el-select>
        </el-form-item>
      </template>-->
    </set-message>
  </div>
</template>
<script>
import setMessage from "@/components/local/setMessage";
import { createMoment } from '@/api/data/wechat/customer/moments'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      crumbs: new Map([
          ['数据中台'], ['企业微信'], ['客户管理'], ['朋友圈群发', '/moments_mass'], ['创建任务']
      ]),
      form: {}
    }
  },
  computed: { ...mapState(['hotelInfo']) },
  components: { setMessage },
  methods: {
    // 保存发布
    handlePublish(ruleForm) {
      const form = Object.assign({}, this.form, ruleForm)
      const params = { ...form, hotelId: this.hotelInfo.id, companyId: this.hotelInfo.storeId }
      createMoment(params).then(({ success }) => {
        if (!success) return
        this.$router.push('/moments_mass')
        this.$message({ message: '保存成功！', type: 'success' })
      })
    }
  }
}
</script>
